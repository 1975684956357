var TopMetaBuchen = function(){
	this.$buchenButton = $(".menu-top-meta-navigation-highlights-container li.buchen > a");
	this.$topMetaNav = $("header .top-meta-navigation .placeholder.middle");
	this.$buchenForm = $("#buchen-header-form");
	this.$closeButton = $(".buchen-header-form-close-button");

	/**
	*
	*/
	this.init = function(){
		var that = this;
		
		that.initEvents();
		that.initResponsiveLinkMagic();
	};

	/**
	*
	*/
	this.initEvents = function(){
		var that = this;
		
		that.initBuchenClickEvent();

		that.initCloseButtonClick();

	};


	this.initResponsiveLinkMagic = function(){
		var that = this;
		var link = that.$buchenButton.attr('href');

		function _do_it(){
			if( W > BP_SM ){
				if (that.$buchenButton.attr('href') != '#') {
					//console.log("cancel bookinglink in top-meta-nav");
					that.$buchenButton.attr('href', '#');
				}
			}else{
				if (that.$buchenButton.attr('href') == '#') {
					//console.log("set bookinglink in top-meta-nav");
					that.$buchenButton.attr('href', link);
				}
			}
		}

		_do_it();
		$(window).resize(function(){
			_do_it();
		});
	};

	/**
	*
	*/
	this.initBuchenClickEvent = function(){
		var that = this;
		//console.log("buchenButton", that.$buchenButton);
		
		that.$buchenButton.click(function(e){
			if (W > BP_SM) {
				e.preventDefault();
			}
			// //console.log("prevented click!");
			
			that.showForm();
		});
	};

	/**
	*
	*/
	this.initCloseButtonClick = function(args){
		var that = this;
		that.$closeButton.click(function(){
			that.hideForm();
		});
	};

	/**
	*
	*/
	this.showForm = function(){
		var that = this;
		// //console.log("show Form");
		// //console.log("form", that.$buchenForm);
		that.$buchenForm.removeClass("hide-form");
	};
	
	/**
	 *
	 */
	this.hideForm = function(){
		var that = this;
		that.$buchenForm.addClass("hide-form");
		
		$( "input.datepicker" ).datepicker("hide");
	};

};