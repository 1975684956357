

/* Define the number of leaves to be used in the animation */
var NUMBER_OF_LEAVES = 7;

/* 
    Called when the "Falling Leaves" page is completely loaded.
*/
function initLeafs()
{
    // if (!$('body').hasClass('ie')) {
        /* Get a reference to the element that will contain the leaves */
        var container = document.getElementById('leafContainer');
        /* Fill the empty container with new leaves */
        for (var i = 0; i < NUMBER_OF_LEAVES; i++) 
        {
            container.appendChild(createALeaf());
        }
    // }
}


/*
    Receives the lowest and highest values of a range and
    returns a random integer that falls within that range.
*/
function randomInteger(low, high)
{
    return low + Math.floor(Math.random() * (high - low));
}


/*
   Receives the lowest and highest values of a range and
   returns a random float that falls within that range.
*/
function randomFloat(low, high)
{
    return low + Math.random() * (high - low);
}


/*
    Receives a number and returns its CSS pixel value.
*/
function pixelValue(value)
{
    return value + 'px';
}


/*
    Returns a duration value for the falling animation.
*/

function durationValue(value)
{
    return value + 's';
}


/*
    Uses an img element to create each leaf. "Leaves.css" implements two spin 
    animations for the leaves: clockwiseSpin and counterclockwiseSpinAndFlip. This
    function determines which of these spin animations should be applied to each leaf.
    
*/
function createALeaf()
{
    /* Start by creating a wrapper div, and an empty img element */
    var leafDiv = document.createElement('div');
    var image = document.createElement('img');
    
    /* Randomly choose a leaf image and assign it to the newly created element */
    image.src = '/wp-content/themes/residenz-ostufer/assets/js/3-classes/FederAnimation/feder'+randomInteger(1, 3)+'.png';
    // //console.log("src", image.src);
	
    // leafDiv.style.top = "-100px";
    
    /* Position the leaf at a random location along the screen */
    // leafDiv.style.right = pixelValue(randomInteger(0, W/3));
    
    /* Randomly choose a spin animation */
    var spinAnimationName = (Math.random() < 0.5) ? 'clockwiseSpin' : 'counterclockwiseSpinAndFlip';
    
    /* Set the -webkit-animation-name property with these values */
    // leafDiv.style.webkitAnimationName = 'fade, drop';
    // image.style.webkitAnimationName = spinAnimationName;
    // image.style.webkitAnimationName = spinAnimationName;
    
    // image.style.opacity = randomFloat(0, 1);
    
    /* Figure out a random duration for the fade and drop animations */
    var fadeAndDropDuration = durationValue(randomFloat(5, 30));
    
    /* Figure out another random duration for the spin animation */
    var spinDuration = durationValue(randomFloat(4, 8));
    /* Set the -webkit-animation-duration property with these values */
    // leafDiv.style.webkitAnimationDuration = fadeAndDropDuration + ', ' + fadeAndDropDuration;
    
    var leafDelay = durationValue(randomFloat(0, 30));
    // leafDiv.style.webkitAnimationDelay = leafDelay + ', ' + leafDelay;
    
    // image.style.webkitAnimationDuration = spinDuration;
    
    
    //STYLING SECTION
    leafDiv.setAttribute('style', 
        'top:-100px;' +
        'right:' + pixelValue(randomInteger(0, W / 3)) + ';' +
        'animation-name:fade, drop;' +
        'animation-duration:' + fadeAndDropDuration + ', ' + fadeAndDropDuration + ';' +
        'animation-delay:' + leafDelay + ', ' + leafDelay + ';'
    );
    image.setAttribute('style', 
        'animation-name:' + spinAnimationName+';' +
        'opacity:' + randomFloat(0.5, 1) + ';' +
        'animation-duration:' + spinDuration + ';'

    );
    //END STYLING SECTION

    // add the <img> to the <div>
    leafDiv.appendChild(image);
    
    /* Return this img element so it can be added to the document */
    return leafDiv;
}


/* Calls the init function when the "Falling Leaves" page is full loaded */
if (W > BP_LG) {
	document.addEventListener('DOMContentLoaded', function() {
		initLeafs();
	});
}