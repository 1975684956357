var MainNavigation = function(){
	this.$main_navigation = $(".main-navigation-wrapper");
	this.$mobile_toggle_buttons = this.$main_navigation.find("input.vrm_submenu_checkbox");
	this.$mobile_main_toggle_button = this.$main_navigation.find("input.vrm_menu_toggle");
	this.$li_submenues = this.$main_navigation.find("li.menu-item-has-children");
	this.submenuOpenState = "submenu-opened";
	this.$top_meta_navigation = $(".top-meta-navigation");
	/**
	*
	*/
	this.init = function(){
		var that = this;
		that.initToggleIcons();
		that.onHoverShowMenu();
	};

	/**
	*
	*/
	this.initToggleIcons = function(){
		var that = this;

		//console.log(that.$mobile_toggle_buttons);
		
		that.$mobile_toggle_buttons.click(function(){
			var $btn = $(this);
			$btn.parent().toggleClass(that.submenuOpenState);
		});
		//console.log("mobile_main_toggle_button", that.$mobile_main_toggle_button);
		
	};

	this.onHoverShowMenu = function(){
		this.$top_meta_navigation.mouseover(function(){
			$("body").removeClass("scrolled");
			$("body").addClass("scrolled-up");
		});
	};
};