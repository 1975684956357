var defaultDatepicker = {
	format: "DD.MM.YYYY",
	dateFormat: "dd.mm.yy",
	separator: " - ",
	applyLabel: "Okay",
	cancelLabel: "Abbrechen",
	fromLabel: "Von",
	toLabel: "Bis",
	customRangeLabel: "Bearbeiten",
	weekLabel: "W",
	monthNames: [
		"Januar",
		"Februar",
		"März",
		"April",
		"Mai",
		"Juni",
		"Juli",
		"August",
		"September",
		"Oktober",
		"November",
		"Dezember",
	],
	monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
	dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
	dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
	dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
	daysOfWeek: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
	firstDay: 1,
};

jQuery(document).ready(function($) {
	"use strict";
	// WICHTIG - Alle //console.log ausgaben ausblenden oder einblenden anhand der URL
	// debug(); in document.ready drin lassen
	// debug();
	// Execute all functions that should be executed on all pages
	// $(".dataTable").dataTable();
	//console.log('WIDTH', W);
	//console.log('HEIGHT', H);

	// globale Settings initialisieren
	var GSC = new GlobalSettingsChild();
	GSC.init();
	
	$.datepicker.regional.de = defaultDatepicker;
	$.datepicker.setDefaults(defaultDatepicker);
});

var GlobalSettingsChild = function(){
	/**
	 * [init description]
	 * @return {[type]} [description]
	 */
	this.init = function(){
		var that = this;
		// Main Navigation
		var MN = new MainNavigation();
		MN.init();

		// datepicker
		$( "input.datepicker" ).datepicker({
			dateFormat: 'dd.mm.yy' 
		});

		var TMB = new TopMetaBuchen();
		TMB.init();

		var TMI = new TopMetaInformationen();
		TMI.init();

		// objectFitImages();

		checkJarallaxInit();
		$(window).resize(function(){
			checkJarallaxInit();
		});
	};

};

$(window).load(function(){
	var rellax = new Rellax('.rellax');
});

checkJarallaxInit = function(){
	if (W >= BP_LG) {
		/* init Jarallax */
		if( !$('body').hasClass('ie') ){
			jarallax(document.querySelectorAll('.jarallax'));
		}
		// jarallax(document.querySelectorAll('.jarallax-keep-img'), {
		// 	keepImg: true,
		// });
	} else {
		jarallax(document.querySelectorAll('.jarallax'), 'destroy');
	}
};