var TopMetaInformationen = function(){
	this.$subMenuLis = $(".top-meta-navigation .menu-top-meta-navigation-container li.menu-item-has-children > a");
	this.$topMetaNav = $("header .top-meta-navigation .placeholder.middle");

	/**
	*
	*/
	this.init = function(){
		var that = this;
		
		that.initEvents();

	};

	/**
	*
	*/
	this.initEvents = function(){
		var that = this;
		
		that.initLinkClickEvent();
	};

	/**
	*
	*/
	this.initLinkClickEvent = function(){
		var that = this;
		//console.log("subMenuLinks", that.$subMenuLis);
		
		that.$subMenuLis.on("click",function(e){
			e.preventDefault();
			//console.log("prevented click!");
			
			var $li = $(this).parent(); 
			//console.log($li);
			
			that.toggleSubMenu($li);
		});
	};

	/**
	*
	*/
	this.getSubMenu = function($li){
		var that = this;
		
		var $submenu = $li.find("> ul.sub-menu");
		//console.log("submenu", $submenu);
		
		return $submenu;
	};
	/**
	*
	*/
	this.toggleSubMenu = function($li){
		var that = this;

		$submenu = that.getSubMenu($li);

		if ($submenu.hasClass("show")) {
			$li.removeClass("show-submenu");
			$submenu.removeClass("show");
		}
		else{
			$li.addClass("show-submenu");
			$submenu.addClass("show");
		}
	};
	
};